<style  lang="less">
.go-cart{
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    background: rgba(255,151,106,0.7);
    position :fixed;
    bottom: 4.5rem;
    right: 1rem;
    z-index: 2000;
    font-size: 1.6rem;
    color: #fff;
    text-align: center;
    line-height:3.2rem;
}
</style>
<template>
        <div class="go-cart" @click="handleGoCart" 
          id="moveDiv"
          @mousedown="down" @touchstart="down"
          @mousemove="move" @touchmove.prevent="move"   
          @mouseup="end" @touchend="end">
            <van-icon name="cart-o" :info="count" />
        </div>
</template>
<script>
import { cartList } from "@/apis/api"
import { mapActions } from 'vuex'
export default {
    name:'cart-btn',
    data(){
        return{
            flags: false,
            position: { x: 0, y: 0 },
            nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
            defaultX:0,
            defaultY:0,
            touchCount:0,
            width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            count:0
        }
    },
    computed: {
      cartcount() {
        return this.$store.state.cartcount.cartcount;
      }
    },
    watch:{
        cartcount:function(v){
            this.count = v
        }
    },
    mounted(){
        this.$store.dispatch('setCartCount').then((res) => {
            this.count =  sessionStorage.getItem('cartcount')
        })
    },
    methods:{
        handleGoCart(){
            this.$router.push({ name:'cart' })
        }, // 实现移动端拖拽
        down(){
            this.flags = true;
            var touch;
            if(event.touches){
                touch = event.touches[0];
            }else {
                touch = event;
            }
            if(this.touchCount == 0){  
                this.defaultX = touch.clientX - 25; //默认位置  当超过去的时候要回到初始位置
                this.defaultY = touch.clientY - 25; //默认位置  当超过去的时候要回到初始位置
            }
            this.touchCount = 1;
            this.position.x = touch.clientX;
            this.position.y = touch.clientY;
            this.dx = moveDiv.offsetLeft;
            this.dy = moveDiv.offsetTop;
        },
        move(){
            if(this.flags){
            var touch ;
            if(event.touches){
                touch = event.touches[0];
            }else {
                touch = event;
            }
            this.nx = touch.clientX - this.position.x;
            this.ny = touch.clientY - this.position.y;
            this.xPum = this.dx+this.nx;
            this.yPum = this.dy+this.ny;
            moveDiv.style.left = this.xPum+"px";
            moveDiv.style.top = this.yPum +"px";
            // //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
            // document.addEventListener("touchmove",function(event){
            //     event.preventDefault();
            // },false);
            }
        },
        //鼠标释放时候的函数
        end(){
            if(this.xPum < -30 || this.yPum < -30 || this.xPum > this.width - 30 || this.yPum > this.height - 30){
                moveDiv.style.left = this.defaultX + "px";
                moveDiv.style.top = this.defaultY + "px";
            }
            this.flags = false;
        },
    }
}
</script>